import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export function handleAPIException(err) {
  if (err.response == undefined) {
    console.log(
      "Invalid error was passed to'handleAPIException()'\nPassed Error =>",
      err
    );
    return;
  }
  if (err.response.config.url.includes("undefined")) return;
  let statusCode = err.response.status;
  let response = err.response.data;
  let formatExceptionMeesage;
  if (statusCode == 500) {
    formatExceptionMeesage = "Something went wrong, Please try again later";
  } else if (Array.isArray(response)) {
    formatExceptionMeesage = handleArrayException(response);
  } else if (typeof response === "object") {
    formatExceptionMeesage = handleObjectException(response);
  } else {
    console.log("unhandled exception", response);
    formatExceptionMeesage = String(response);
  }
  if (err.response.config.url.includes("is-checked-in")) return;
  if (err.response.data.code == "token_not_valid") return;
  // if (err.response.config.method == "post") return;
  Vue.$toast({
    component: ToastificationContent,
    position: "top-right",
    props: {
      title: "Fail",
      variant: "danger",
      text: formatExceptionMeesage,
      icon: "XIcon",
    },
  });
}

function handleArrayException(arr) {
  return arr.reduce((acc, item) => {
    if (acc == "") {
      return item;
    } else {
      return acc + ", " + item;
    }
  }, "");
}

function handleObjectException(obj) {
  if (obj.non_field_errors) {
    return obj.non_field_errors[0];
  } else {
    let result = [];
    for (let key in obj) {
      result.push(`${key}: ${obj[key]}`);
    }
    return result.join("\n");
  }
}
