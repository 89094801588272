export default [
  {
    path: "/",
    name: "home",
    redirect: "/statistics",
    component: () => import("@/views/home/Home.vue"),
    meta: {
      isAuth: true,
    },
    children: [
      {
        path: "/statistics",
        modelName: "employee",
        name: "statistics",
        component: () => import("@/views/statistics/Statistcs.vue"),
        meta: {
          isDashboard: true,
          permissions: ["base.view_admin_dahsboard"],
        },
      },

      {
        path: "/employee/list",
        name: "employee-list",
        component: () => import("@/views/employees/EmployeeListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_employee"],
          model: "employee",
        },
      },
      {
        path: "/devices-list",
        name: "devices-list",
        component: () => import("@/views/devices/DeviceListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["base.view_logindevice"],
          model: "logindevice",
        },
      },
      {
        path: "/scheduled-actions",
        name: "scheduled-actions",
        component: () => import("@/views/scheduledAction/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["base.view_admin_dahsboard"],
          model: "scheduledaction",
        },
      },
      {
        path: "/scheduled-action/form/:id?",
        name: "scheduled-action-form",
        component: () => import("@/views/scheduledAction/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "scheduledaction",
          permissions: ["base.view_admin_dahsboard"],
          isForm: true,
        },
      },
      {
        path: "/accural-rules",
        name: "accural-rules",
        component: () => import("@/views/timeOff/accurals/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["timeoff.view_accuralrules"],
          model: "accuralrules",
        },
      },
      {
        path: "/accural-rule/form/:id?",
        name: "accural-rule-form",
        component: () => import("@/views/timeOff/accurals/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "accuralrules",
          permissions: ["timeoff.add_accuralrules"],
          isForm: true,
        },
      },

      {
        path: "/employee/add/",
        name: "employee-add",
        component: () => import("@/views/employees/Employee-Add.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.add_employee"],
          model: "employee",
        },
      },
      {
        path: "/employee/lis/view/:id",
        name: "employee-lis-view",
        component: () => import("@/views/employees/EmployeeDetalis.vue"),
        meta: {
          isDashboard: true,
          model: "employee",
          permissions: ["employee.view_employee"],
          isForm: true,
        },
      },
      {
        path: "/employee-bank/",
        name: "employee-bank",
        component: () => import("@/views/bank/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_employeebank"],
          model: "employeebank",
        },
      },
      {
        path: "/contract-type/",
        name: "contract-type",
        component: () => import("@/views/contract/ContractTypeView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_employeecontracttype"],
          model: "employeecontracttype",
        },
      },
      {
        path: "/employee-contract/list",
        name: "employee-contract-list",
        component: () => import("@/views/contract/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_employeecontract"],
          model: "employeecontract",
        },
      },
      {
        path: "/employee-contract/form/:id?",
        name: "employee-contract-form",
        component: () => import("@/views/contract/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "employeecontract",
          permissions: ["employee.add_employeecontract"],
          isForm: true,
        },
      },
      {
        path: "/employee-end-of-service/form/:id?",
        name: "employee-end-of-service-form",
        component: () => import("@/views/employee-end-of-service/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "endofservice",
          permissions: ["employee.add_endofservice"],
          isForm: true,
        },
      },
      {
        path: "/employee-end-of-service/list",
        name: "employee-end-of-service-list",
        component: () => import("@/views/employee-end-of-service/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "endofservice",
          permissions: ["employee.view_endofservice"],
        },
      },
      {
        path: "/jobs/job-title",
        name: "job-title",
        component: () => import("@/views/jobs/JobTitleView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_jobtitle"],
          model: "jobtitle",
        },
      },
      {
        path: "/jobs/job-location",
        name: "job-location",
        component: () => import("@/views/jobs/JobLocationView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_joblocation"],
          model: "joblocation",
        },
      },
      {
        path: "/employee-national-card-source",
        name: "employee-national-card-source",
        component: () =>
          import(
            "@/views/EmployeeNationalCardSource/EmployeeNationalCardSourceView.vue"
          ),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_employeenationalcardsource"],
          model: "employeenationalcardsource",
        },
      },
      {
        path: "/departments",
        name: "departments",
        component: () => import("@/views/departments/DepartmentView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_department"],
          model: "department",
        },
      },
      {
        path: "/department-unit",
        name: "department-unit",
        component: () => import("@/views/departments/DepartmentUnitView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_departmentunit"],
          model: "departmentunit",
        },
      },
      {
        path: "/Equipment-Brand",
        name: "Equipment-Brand",
        component: () => import("@/views/equipment/EquipmentBrandView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["equipment.view_equipmentbrand"],
          model: "equipmentbrand",
        },
      },
      {
        path: "/Equipments",
        name: "Equipments",
        component: () => import("@/views/equipment/EquipmentView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["equipment.view_equipment"],
          model: "equipment",
        },
      },
      {
        path: "/Equipment-Details/:id",
        name: "Equipment-Details",
        component: () => import("@/views/equipment/equipmentsDetails.vue"),
        meta: {
          isDashboard: true,
          model: "equipment",
          permissions: ["equipment.view_equipment"],
        },
      },

      {
        path: "/Work-schedule/:id",
        name: "Work-schedule-show",
        component: () =>
          import("@/views/work-schedule/WorkScheduleEmployees.vue"),
        meta: {
          isDashboard: true,
          model: "workschedule",
          permissions: ["employee.view_workschedule"],
        },
      },
      {
        path: "/Work-schedule/set-employees/:id",
        name: "Work-schedule-set-employees",
        component: () =>
          import("@/views/work-schedule/WorkScheduleSetEmployees.vue"),
        meta: {
          isDashboard: true,
          model: "workschedule",
          permissions: ["employee.view_workschedule"],
        },
      },
      {
        path: "/Work-schedule",
        name: "Work-schedule",
        component: () => import("@/views/work-schedule/WorkScheduleView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_workschedule"],
          model: "workschedule",
        },
      },
      {
        path: "/Attendance",
        name: "Attendance",
        component: () => import("@/views/attendance/AttendanceListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["attendance_core.view_actualattendance"],
          model: "actualattendance",
        },
      },
      {
        path: "/Overtime",
        name: "Overtime",
        component: () => import("@/views/attendance/OvertimeListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["attendance_core.view_actualattendance"],
          model: "actualattendance",
        },
      },

      {
        path: "/Attendance/:id/",
        name: "Attendance-employee-list",
        component: () => import("@/views/attendance/AttendanceEmployee.vue"),
        meta: {
          isDashboard: true,
          model: "actualattendance",
          permissions: ["attendance_core.add_actualattendance"],
        },
      },
      {
        path: "/attendance-report",
        name: "attendance-report",
        component: () => import("@/views/attendance/ReportView.vue"),

        meta: {
          isDashboard: true,
          permissions: [
            "attendance_core.add_actualattendance",
            "attendance_core.view_actualattendance",
            "attendance_core.change_actualattendance",
            "attendance_core.delete_actualattendance",
          ],
        },
      },
      {
        path: "/attendance-adjustment-list",
        name: "attendance-adjustment-list",
        component: () => import("@/views/attendence-adjustment/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "changerequest",
          permissions: ["attendance_adjustment.view_changerequest"],
        },
      },
      {
        path: "/attendance-adjustment/add",
        name: "attendance-adjustment-add",
        component: () => import("@/views/attendence-adjustment/AddView.vue"),
        meta: {
          isDashboard: true,
          model: "changerequest",
          permissions: ["attendance_adjustment.add_changerequest"],
          isForm: true,
        },
      },
      {
        path: "/attendance-adjustment/edit/:id",
        name: "attendance-adjustment-edit",
        component: () => import("@/views/attendence-adjustment/EditView.vue"),
        meta: {
          isDashboard: true,
          model: "changerequest",
          permissions: ["attendance_adjustment.change_changerequest"],
          isForm: true,
        },
      },
      {
        path: "/Work-shift",
        name: "Work-shift",
        component: () => import("@/views/work-schedule/WorkShift.vue"),
        meta: {
          isDashboard: true,
          permissions: ["attendance_core.view_workshift"],
          model: "workshift",
        },
      },

      // {
      //   path: '/Allowance',
      //   name: 'allowance',
      //   component: () => import('@/views/allowance/allowance.vue'),
      //   meta: {
      //     isDashboard: true,
      //     permissions: ['attendance_core.view_workshift'],
      //     model: 'workshift',
      //   },
      // },

      {
        path: "/approve-group",
        name: "approve-group",
        component: () => import("@/views/approve/ApproveGroupView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["approve_core.view_approvegroup"],
          model: "approvegroup",
        },
      },


      
//  ------- aprovals start -------
      {
        path: "/approvals",
        name: "approvals-list",
        component: () => import("@/views/approvals/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["hr_approvals.view_approvalrequest"],
          model: "ApprovalRequest",
        },
      },

      {
        path: "/approvals-form/:id?",
        name: "approvals-form",
        component: () => import("@/views/approvals/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "ApprovalRequest",
          permissions: ["hr_approvals.add_approvalrequest"],
          isForm: true,
        },
      },

      

      {
        path: "/approval-category",
        name: "approval-category-list",
        component: () => import("@/views/approval-category/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["hr_approvals.view_approvalcategory"],
          model: "ApprovalCategory",
        },
      },

      {
        path: "/approval-category-form/:id?",
        name: "approval-category-form",
        component: () => import("@/views/approval-category/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "ApprovalCategory",
          permissions: ["hr_approvals.add_approvalcategory"],
          isForm: true,
        },
      },
      

      // {
      //   path: '/Approve-Role-Employees/:id',
      //   name: 'timeOffRoles-show',
      //   component: () => import('@/views/timeOff/rolesEmployee.vue'),
      // },

      {
        path: "/timeoff-types",
        name: "timeoff-types",
        component: () => import("@/views/timeOff/types/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["timeoff.view_timeofftype"],
          model: "timeofftype",
        },
      },
      {
        path: "/timeoff-types/add/",
        name: "timeoff-type-add",
        component: () => import("@/views/timeOff/types/AddView.vue"),
        meta: {
          isDashboard: true,
          model: "timeofftype",
          permissions: ["timeoff.add_timeofftype"],
          isForm: true,
        },
      },
      {
        path: "/timeoff-types/edit/:id",
        name: "timeoff-type-edit",
        component: () => import("@/views/timeOff/types/EditView.vue"),
        meta: {
          isDashboard: true,
          model: "timeofftype",
          permissions: ["timeoff.change_timeofftype"],
          isForm: true,
        },
      },
      {
        path: "/timeoff-requests",
        name: "timeoff-request-list",
        component: () => import("@/views/timeOff/requests/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["timeoff.view_timeoffrequest"],
          model: "timeoffrequest",
        },
      },
      {
        path: "/timeoff-request-payment-reason",
        name: "timeoff-request-payment-list",
        component: () =>
          import("@/views/timeOff/requests/RequestFullPayment.vue"),
        meta: {
          isDashboard: true,
          permissions: ["timeoff.view_timeoffrequestfullpayment"],
          model: "timeoffrequestfullpayment",
        },
      },

      {
        path: "/timeoff-request/form/:id?",
        name: "timeoff-request-form",
        component: () => import("@/views/timeOff/requests/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "timeoffrequest",
          permissions: ["timeoff.add_timeoffrequest"],
          isForm: true,
        },
      },
      {
        path: "/timeoff-balances/list",
        name: "timeoff-balances-list",
        component: () => import("@/views/timeOff/balance/BalanceListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["timeoff.view_timeofftypeemployeebalance"],
          model: "timeofftypeemployeebalanceupdate",
        },
      },
      {
        path: "/timeoff-balance-updates/:id",
        name: "timeoff-balance-updates",
        component: () =>
          import("@/views/timeOff/balance/BalanceUpdateView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["timeoff.add_timeofftypeemployeebalanceupdate"],
          model: "timeofftypeemployeebalanceupdate",
        },
      },
      {
        path: "/employee-assessment/",
        name: "employee-assessment-list",
        component: () => import("@/views/assessment/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_employeeassessment"],
          model: "employeeassessment",
        },
      },

      {
        path: "/employee-assessment-form/:id?",
        name: "employee-assessment-form",
        component: () => import("@/views/assessment/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "employeeassessment",
          permissions: ["employee.add_employeeassessment"],
          isForm: true,
        },
      },

      {
        path: "/employee-assessment-line-type/",
        name: "employee-assessment-line-type-list",
        component: () => import("@/views/assessment-type/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_employeeassessmentlinetype"],
          model: "employeeassessmentlinetype",
        },
      },
      {
        path: "/employee-assessment-line-type-form/:id?",
        name: "employee-assessment-line-type-form",
        component: () => import("@/views/assessment-type/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "employeeassessmentlinetype",
          permissions: ["employee.add_employeeassessmentlinetype"],
          isForm: true,
        },
      },
      {
        path: "/employee-assessment-form-template/",
        name: "employee-assessment-form-template-list",
        component: () => import("@/views/assessment-template/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_employeeassessmentformtemplate"],
          model: "employeeassessmentformtemplate",
        },
      },
      {
        path: "/employee-assessment-form-template-form/:id?",
        name: "employee-assessment-form-template-form",
        component: () => import("@/views/assessment-template/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "employeeassessmentformtemplate",
          permissions: ["employee.add_employeeassessmentformtemplate"],
          isForm: true,
        },
      },

      {
        path: "/employee-assessment-forma/",
        name: "employee-assessment-forma-list",
        component: () => import("@/views/assessment-form/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_employeeassessmentform"],
          model: "employeeassessmentform",
        },
      },
      {
        path: "/employee-assessment-forma/:id?",
        name: "employee-assessment-forma-form",
        component: () => import("@/views/assessment-form/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "employeeassessmentform",
          permissions: ["employee.add_employeeassessmentform"],
          isForm: true,
        },
      },

      {
        path: "/employee-assessment-batch/",
        name: "employee-assessment-batch-list",
        component: () => import("@/views/assessment-batch/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_employeeassessmentbatch"],
          model: "employeeassessmentbatch",
        },
      },
      {
        path: "/employee-assessment-batch-form/:id?",
        name: "employee-assessment-batch-form",
        component: () => import("@/views/assessment-batch/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "employeeassessmentbatch",
          permissions: ["employee.add_employeeassessmentbatch"],
          isForm: true,
        },
      },

      {
        path: "/assessment-to-do",
        name: "assessment-to-do",
        component: () => import("@/views/assessment/AssessmentToDo.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_employeeassessmentline"],
          model: "employeeassessmentline",
        },
      },

      {
        path: "/assessment-to-do/user",
        name: "assessment-to-do-user",
        component: () => import("@/views/user-view/AssessmentsToDoView.vue"),
        meta: {
          layout: "user",
          permissions: ["employee.view_employeeassessmentline"],
          model: "employeeassessmentline",
        },
      },

      {
        path: "/employee-assessment-salary-adjustment-rule/",
        name: "employee-assessment-salary-adjustment-rule-list",
        component: () =>
          import("@/views/assessments-salary-rules/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["employee.view_employeeassessmentsalaryadjustmentrule"],
          model: "employeeassessmentsalaryadjustmentrule",
        },
      },
      {
        path: "/employee-assessment-salary-adjustment-rule/:id?",
        name: "employee-assessment-salary-adjustment-rule-form",
        component: () =>
          import("@/views/assessments-salary-rules/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "employeeassessmentsalaryadjustmentrule",
          permissions: ["employee.add_employeeassessmentsalaryadjustmentrule"],
          isForm: true,
        },
      },

      {
        path: "/payroll/payslip-batch",
        name: "payroll-payslip-batch-list",
        component: () => import("@/views/payroll/payslip-batch/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["payroll.view_payslipbatch"],
          model: "payslipbatch",
        },
      },
      {
        path: "/payroll/payslip-batch-form/:id?",
        name: "payroll-payslip-batch-form",
        component: () => import("@/views/payroll/payslip-batch/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "payslipbatch",
          permissions: ["payroll.add_payslipbatch"],
          isForm: true,
        },
      },

      {
        path: "/payroll/payslip-updates",
        name: "payroll-payslip-updates",
        component: () => import("@/views/payroll/PayslipUpdateView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["payroll.view_payslipupdate"],
          model: "payslipupdate",
        },
      },
      {
        path: "/payroll/payslip/list",
        name: "payroll-payslip-list",
        component: () => import("@/views/payroll/payslip/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["payroll.view_payslip"],
          model: "payslip",
        },
      },

      {
        path: "/payroll/payslip/my-payslip",
        name: "employee-payslips",
        component: () =>
          import("@/views/payroll/payslip/CurrentUserPayslips.vue"),
        meta: {
          isDashboard: true,
          permissions: ["payroll.view_payslip"],
        },
      },

      {
        path: "/payroll/payslip/my-payslip/user",
        name: "employee-payslips-user",
        component: () => import("@/views/user-view/MyPayslipsUserView.vue"),
        meta: {
          layout: "user",
          permissions: ["payroll.view_payslip"],
        },
      },

      {
        path: "/payroll/payslip/form/:id?",
        name: "payroll-payslip-form",
        component: () => import("@/views/payroll/payslip/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "payslip",
          permissions: ["payroll.add_payslip"],
          isForm: true,
        },
      },
      {
        path: "/payroll/payslip/batch/:batch/:payslip?",
        name: "payroll-payslip-form-batch",
        component: () => import("@/views/payroll/payslip/FormViewForBatch.vue"),
        meta: {
          isDashboard: true,
          model: "payslip",
          permissions: ["payroll.add_payslipbatch"],
          isForm: true,
        },
      },
      {
        path: "/brands",
        name: "brands-list",
        component: () => import("@/views/brands/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "brand",
          permissions: ["base.view_brand"],
          isForm: true,
        },
      },

      {
        path: "/attachment-type",
        name: "attachment-type-list",
        component: () => import("@/views/attachment-type/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "attachmenttype",
          permissions: ["base.view_attachmenttype"],
          isForm: true,
        },
      },
      {
        path: "/companies",
        name: "companies-list",
        component: () => import("@/views/company/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "company",
          permissions: ["base.view_company"],
          isForm: true,
        },
      },
      {
        path: "/projects",
        name: "projects-list",
        component: () => import("@/views/project/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "project",
          permissions: ["base.view_project"],
          isForm: true,
        },
      },
      {
        path: "/states",
        name: "states-list",
        component: () => import("@/views/state/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "state",
          permissions: ["base.view_state"],
          isForm: true,
        },
      },
      {
        path: "/universities",
        name: "universities-list",
        component: () => import("@/views/university/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "university",
          permissions: ["base.view_university"],
          isForm: true,
        },
      },
      {
        path: "/legal",
        name: "legal-list",
        component: () => import("@/views/legal/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "legal",
          permissions: ["base.view_legalrecord"],
          isForm: true,
        },
      },
      {
        path: "/announcement",
        name: "announcement-list",
        component: () => import("@/views/announcements/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "announcements",
          permissions: ["base.view_announcement"],
          isForm: true,
        },
      },
      {
        path: "/ann-details/:id",
        name: "announcement-details",
        component: () =>
          import(
            "@/@core/layouts/components/app-navbar/components/AnnouncmentDetails.vue"
          ),
      },
      {
        path: "/major",
        name: "major-list",
        component: () => import("@/views/major/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "major",
          permissions: ["base.view_major"],
          isForm: true,
        },
      },
      {
        path: "/holiday",
        name: "holiday-list",
        component: () => import("@/views/holiday/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "holiday",
          permissions: ["base.view_holiday"],
          isForm: true,
        },
      },
      {
        path: "/announcement-form/:id?",
        name: "announcement-form",
        component: () => import("@/views/announcements/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "announcement",
          permissions: ["base.add_announcement"],
          isForm: true,
        },
      },
      {
        path: "/legal-form/:id?",
        name: "legal-form",
        component: () => import("@/views/legal/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "legalrecord",
          permissions: ["base.add_legalrecord"],
          isForm: true,
        },
      },

      {
        path: "/skill-types",
        name: "skill-types-list",
        component: () =>
          import("@/views/recruitment/skills/skill-type/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "skilltype",
          permissions: ["recruitment.view_skilltype"],
          isForm: false,
        },
      },
      {
        path: "/skill-levels",
        name: "skill-levels-list",
        component: () =>
          import("@/views/recruitment/skills/skill-level/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "skilllevel",
          permissions: ["recruitment.view_skilllevel"],
          isForm: true,
        },
      },
      {
        path: "/skills",
        name: "skills-list",
        component: () =>
          import("@/views/recruitment/skills/skill/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "skill",
          permissions: ["recruitment.view_skill"],
          isForm: true,
        },
      },
      {
        path: "/action",
        name: "actions-list",
        component: () =>
          import("@/views/recruitment/actions/action/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "action",
          permissions: ["recruitment.view_action"],
          isForm: true,
        },
      },
      {
        path: "/application-stage-action",
        name: "application-stage-action",

        component: () =>
          import("@/views/recruitment/jobs/ApplicationStagesAction.vue"),
        meta: {
          isDashboard: true,
          model: "applicationstageaction",
          permissions: ["recruitment.view_applicationstageaction"],
          isForm: true,
        },
      },
      {
        path: "/workplace-types",
        name: "workplace-types-list",
        component: () =>
          import("@/views/recruitment/workplace-type/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "workplacetype",
          permissions: ["recruitment.view_workplacetype"],
          isForm: true,
        },
      },

      {
        path: "/certification-info",
        name: "certification-info-list",
        component: () =>
          import("@/views/recruitment/certification-info/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "certificationinfo",
          permissions: ["recruitment.view_certificationinfo"],
          isForm: true,
        },
      },
      {
        path: "/refuse-reason",
        name: "refuse-reason-list",
        component: () =>
          import("@/views/recruitment/refuse-reason/ListView.vue"),
        // meta: {
        //   isDashboard: true,
        //   model: "refuser",
        //   permissions: ["recruitment.view_refuser"],
        //   isForm: true,
        // },
      },

      {
        path: "/application",
        name: "application-list",
        component: () => import("@/views/recruitment/application/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "application",
          permissions: ["recruitment.view_application"],
          isForm: true,
        },
      },
      {
        path: "/application-form/:id?",
        name: "application-form",
        component: () => import("@/views/recruitment/application/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "application",
          permissions: ["recruitment.add_application"],
          isForm: true,
        },
      },

      {
        path: "/application/application-skill",
        name: "application-skill-list",
        component: () =>
          import(
            "@/views/recruitment/application/application-skill/ListView.vue"
          ),
        meta: {
          isDashboard: true,
          model: "applicationskill",
          permissions: ["recruitment.view_applicationskill"],
          isForm: true,
        },
      },
      {
        path: "/application/application-skill-form/:id?",
        name: "application-skill-form",
        component: () =>
          import(
            "@/views/recruitment/application/application-skill/FormView.vue"
          ),
        meta: {
          isDashboard: true,
          model: "applicationskill",
          permissions: ["recruitment.add_applicationskill"],
          isForm: true,
        },
      },

      {
        path: "/application/application-source",
        name: "application-source-list",
        component: () =>
          import(
            "@/views/recruitment/application/application-source/ListView.vue"
          ),
        meta: {
          isDashboard: true,
          model: "applicationsource",
          permissions: ["recruitment.view_applicationsource"],
          isForm: true,
        },
      },
      {
        path: "/application/application-stage",
        name: "application-stage-list",
        component: () =>
          import(
            "@/views/recruitment/application/application-stage/ListView.vue"
          ),
        meta: {
          isDashboard: true,
          model: "applicationstage",
          permissions: ["recruitment.view_applicationstage"],
          isForm: true,
        },
      },

      {
        path: "/hiring-request",
        name: "hiring-request-list",
        component: () =>
          import("@/views/recruitment/hiring-request/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "hiringrequest",
          permissions: ["recruitment.view_hiringrequest"],
          isForm: true,
        },
      },
      {
        path: "/hiring-request-form/:id?",
        name: "hiring-request-form",
        component: () =>
          import("@/views/recruitment/hiring-request/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "hiringrequest",
          permissions: ["recruitment.add_hiringrequest"],
          isForm: true,
        },
      },
      {
        path: "/hiring-request-create-job/:id?",
        name: "hiring-request-create-job",
        component: () =>
          import("@/views/recruitment/hiring-request/CreateJob.vue"),
      },
      {
        path: "/jobs",
        name: "jobs-list",
        component: () => import("@/views/recruitment/jobs/Jobs.vue"),
        meta: {
          isDashboard: true,
          model: "jobs",
          permissions: ["recruitment.view_job"],
          isForm: true,
        },
      },
      {
        path: "/jobs-form/:id?/:hiring_request?",
        name: "jobs-form",
        component: () => import("@/views/recruitment/jobs/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "jobs",
          permissions: ["recruitment.add_job"],
          isForm: true,
        },
      },
      {
        path: "/job/:id",
        name: "JobDetails",
        component: () => import("@/views/recruitment/jobs/JobDetails.vue"),
        meta: {
          isDashboard: true,
          model: "jobs",
          permissions: ["recruitment.add_job"],
          isForm: true,
        },
      },
      {
        path: "/jobs-kanban/:id?",
        name: "jobs-kanban",
        component: () => import("@/views/recruitment/jobs/JobsKanban.vue"),
      },
      {
        path: "/application-details/:id",
        name: "application-details",
        component: () =>
          import("@/views/recruitment/jobs/ApplicationDetails.vue"),
        meta: {
          isDashboard: true,
          model: "employeeassessmentlinetype",
          permissions: ["recruitment.view_application"],
        },
      },
      // ... existing code ...

      // Add these routes to your router configuration
      {
        path: "/approval-list",
        name: "approval-list",
        component: () => import("@/views/recruitment/approval/ListView.vue"),
        meta: {
          requiresAuth: true,
          title: "Approvals",
        },
      },
      {
        path: "/approval-form/:id?",
        name: "approval-form",
        component: () => import("@/views/recruitment/approval/FormView.vue"),
        meta: {
          requiresAuth: true,
          title: "Approval Form",
        },
      },
      {
        path: "/group",
        name: "groups-list",
        component: () => import("@/views/groups/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["base.view_admin_dahsboard"],
          model: "group",
        },
      },
      {
        path: "/group/form/:id?",
        name: "groups-form",
        component: () => import("@/views/groups/FormView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["base.view_admin_dahsboard"],
          model: "group",
        },
      },
      {
        path: "/user",
        name: "user-list",
        component: () => import("@/views/user/ListView.vue"),
        meta: {
          isDashboard: true,
          model: "user",
          permissions: ["base.view_user"],
        },
      },
      {
        path: "/notification",
        name: "user-notification-list",
        component: () => import("@/views/notifications/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["base.view_admin_dahsboard"],
          model: "notification",
        },
      },
      {
        path: "/log-entry",
        name: "log-entry-list",
        component: () => import("@/views/logs/ListView.vue"),
        meta: {
          isDashboard: true,
          permissions: ["base.view_admin_dahsboard"],
          model: "logentry",
        },
      },
      {
        path: "/user/form/:id?",
        name: "user-form",
        component: () => import("@/views/user/FormView.vue"),
        meta: {
          isDashboard: true,
          model: "user",
          permissions: ["base.add_user"],
        },
      },
      // {
      //   path: "/permission",
      //   name: "permissions-list",
      //   component: () => import("@/views/permissions/ListView.vue"),
      //   meta: {
      //     isDashboard: true,
      //     model: "permission",
      //   },
      // },
      {
        path: "/access-denied",
        name: "permission-denied",
        component: () => import("@/views/miscellaneous/AccessDenied.vue"),
        meta: {
          isDashboard: true,
        },
      },
    ],
  },

  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/errors/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/login/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
      isAuth: false,
    },
  },

  {
    path: "/coming-soon",
    name: "misc-coming-soon",
    component: () => import("@/views/miscellaneous/ComingSoon.vue"),
    meta: {
      layout: "user",
    },
  },

  {
    path: "/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/errors/NotAuthorized.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/under-maintenance",
    name: "misc-under-maintenance",
    component: () => import("@/views/miscellaneous/UnderMaintenance.vue"),
    meta: {
      layout: "full",
    },
  },
];
